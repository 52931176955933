var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseForm',{ref:"form",attrs:{"id":"market-rates-form","disabled":_vm.disabled,"form-type":_vm.formType,"layout":_vm.layout,"loading":_vm.loading,"row":_vm.row,"select-models":_vm.selectModels},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-snackbar',{staticClass:"errormsg",attrs:{"color":"error","multi-line":_vm.isModeMultiLine,"timeout":0,"vertical":_vm.isModeVertical,"type":"error","absolute":""},model:{value:(_vm.displayerr),callback:function ($$v) {_vm.displayerr=$$v},expression:"displayerr"}},[_vm._v(" "+_vm._s(_vm.errmsg)+" "),_c('v-btn',{attrs:{"id":"market-rates-form-button-close","dark":"","flat":""},on:{"click":function($event){_vm.displayerr = false}}},[_vm._v(" Close ")])],1),(!_vm.isModeView)?_c('v-btn',{staticClass:"btn-secondaryaction",attrs:{"id":"market-rates-form-button-cancel"},on:{"click":_vm.cancelAction}},[_vm._v(" Cancel ")]):_c('CRSelect',{staticClass:"d-inline-flex",attrs:{"item-text":"marketName","items":_vm.allMarkets,"placeholder":_vm.row.marketName},on:{"input":_vm.changeMarket}}),(_vm.isModeAdd)?_c('v-btn',{attrs:{"id":"market-rates-form-add","disabled":_vm.loading,"loading":_vm.loading,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Add Market Rate ")]):(_vm.disabled)?_c('v-btn',{attrs:{"id":"market-rates-form-button-edit","color":"primary"},on:{"click":_vm.enableEdit}},[_vm._v(" Edit Market Rate ")]):_c('v-btn',{attrs:{"id":"market-rates-form-button-save","disabled":_vm.loading,"loading":_vm.loading,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Save Market Rate ")])]},proxy:true},{key:"address",fn:function(){return [_c('div',[_vm._v("Address")]),_c('v-combobox',{ref:"addr",attrs:{"id":"market-rates-form-combobox-address","disabled":_vm.disabled,"items":_vm.asyncitems,"search-input":_vm.search,"box":"","flat":"","solo":"","error-messages":_vm.addresserr,"rules":[
        _vm.isRequired(true, _vm.isNotEmpty, {
          req: 'Address Required',
          error: 'Address Required',
        }),
      ]},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.clearOldAddrErr},model:{value:(_vm.selectModels.formattedAddress),callback:function ($$v) {_vm.$set(_vm.selectModels, "formattedAddress", $$v)},expression:"selectModels.formattedAddress"}})]},proxy:true},{key:"market-rates",fn:function(){return [_c('table',{staticClass:"table is-striped"},[_c('colgroup',[_c('col',{staticStyle:{"width":"40%"},attrs:{"span":"1"}}),_c('col',{staticStyle:{"width":"20%"},attrs:{"span":"1"}}),_c('col',{staticStyle:{"width":"20%"},attrs:{"span":"1"}}),_c('col',{staticStyle:{"width":"20%"},attrs:{"span":"1"}})]),_c('thead',[_c('th',{staticStyle:{"text-align":"left"}},[_vm._v("Rate Type")]),_c('th',[_vm._v("Floor Rate")]),_c('th',[_vm._v("Low Rate")]),_c('th',[_vm._v("High Rate")])]),_c('tbody',[_vm._l((_vm.row.marketRateDTOs),function(item,index){return _c('tr',{key:item.marketRateId},[_c('td',{staticClass:"rates-td-padded"},[_c('b',[_vm._v(_vm._s(item.vehicleTypeDTO.label))]),_c('p',[_vm._v(_vm._s(item.marketRateTypeDTO.label))])]),_c('td',[_c('CRInput',{ref:index + 'floorRate',refInFor:true,staticStyle:{"margin-top":"12px"},attrs:{"id":`market-rates-form-input-floor-rate-${index}`,"disabled":_vm.disabled,"type":"number"},model:{value:(item.floorRate),callback:function ($$v) {_vm.$set(item, "floorRate", $$v)},expression:"item.floorRate"}})],1),_c('td',[_c('CRInput',{ref:index + 'lowRate',refInFor:true,staticStyle:{"margin-top":"12px"},attrs:{"id":`market-rates-form-input-low-rate-${index}`,"disabled":_vm.disabled,"type":"number","rules":[
                _vm.isRequired(true, _vm.isNotEmpty, {
                  req: 'Rate Required',
                  error: 'Rate Required',
                }),
              ]},model:{value:(item.lowRate),callback:function ($$v) {_vm.$set(item, "lowRate", $$v)},expression:"item.lowRate"}})],1),_c('td',[_c('CRInput',{ref:index + 'highRate',refInFor:true,staticStyle:{"margin-top":"12px"},attrs:{"id":`market-rates-form-input-high-rate-${index}`,"disabled":_vm.disabled,"type":"number","rules":[
                _vm.isRequired(true, _vm.isNotEmpty, {
                  req: 'Rate Required',
                  error: 'Rate Required',
                }),
              ]},model:{value:(item.highRate),callback:function ($$v) {_vm.$set(item, "highRate", $$v)},expression:"item.highRate"}})],1)])}),_c('tr',[_c('td',{staticClass:"rates-td-padded"},[_c('b',[_vm._v("Driver Rate")]),_c('p',[_vm._v("Daily Rate")])]),_c('td',[_c('CRInput',{ref:"driverRate",staticStyle:{"margin-top":"12px"},attrs:{"id":`market-rates-form-input-additional-driver-rate`,"disabled":_vm.disabled,"type":"number","rules":[
                _vm.isRequired(true, _vm.isNotEmpty, {
                  req: 'Rate Required',
                  error: 'Rate Required',
                }),
              ]},model:{value:(_vm.driverRate.rate),callback:function ($$v) {_vm.$set(_vm.driverRate, "rate", $$v)},expression:"driverRate.rate"}})],1)])],2)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }