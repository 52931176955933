<template>
  <div class="sheet-form">
    <v-form ref="form">
      <div v-if="!hideHeader" class="form-main-header">
        <h1>
          {{ capitalize(formType) }}
        </h1>
        <div class="top-items">
          <slot name="top" />
        </div>
        <v-divider />
      </div>

      <v-layout column>
        <div v-if="row" class="form-container">
          <div v-for="section in layout" :key="section.id" class="width-30">
            <div>
              <div
                v-for="field in section.fields"
                :key="field.key"
                :class="overClass(field)"
              >
                <div v-if="field.meta ? field.toggle : true">
                  <div v-if="field.type === 'empty'" class="empty" />
                  <CRInput
                    v-if="field.type === 'text' || field.type === 'password'"
                    :id="`base-form-text-input-${field.key}`"
                    :ref="field.key"
                    :error-messages="errors[field.key]"
                    :value="op(row, field.key)"
                    :disabled="field.forceDisabled ? true : disabled"
                    :tooltip="field.tooltip"
                    :color="field.color"
                    :rules="field.rules"
                    :floatinglabel="field.label"
                    :type="field.type"
                    @change="
                      (evt) => {
                        onChange(evt, field.key, field)
                      }
                    "
                  />

                  <CRInput
                    v-if="field.type === 'phone'"
                    :id="`base-form-phone-input-${field.key}`"
                    :ref="field.key"
                    v-mask="['(###) ###-####', '+## ## #### ####']"
                    :error-messages="errors[field.key]"
                    :value="op(row, field.key)"
                    :disabled="field.forceDisabled ? true : disabled"
                    :tooltip="field.tooltip"
                    :color="field.color"
                    :rules="field.rules"
                    :floatinglabel="field.label"
                    @change="
                      (evt) => {
                        onChange(evt, field.key, field)
                      }
                    "
                  />

                  <CRInput
                    v-if="field.type === 'date'"
                    :id="`base-form-date-input-${field.key}`"
                    :ref="field.key"
                    :error-messages="errors[field.key]"
                    :value="op(row, field.key)"
                    :disabled="field.forceDisabled ? true : disabled"
                    :tooltip="field.tooltip"
                    :color="field.color"
                    :rules="field.rules"
                    :floatinglabel="field.label"
                    :type="field.type"
                    @change="
                      (evt) => {
                        onChange(evt, field.key, field)
                      }
                    "
                  />

                  <slot v-if="field.type === 'slot'" :name="field.label" />

                  <template v-if="field.type === 'select'">
                    <label>
                      {{ field.label }}
                    </label>
                    <div :id="`base-form-select-${field.key}-type-container`">
                      <CRSelect
                        :id="`base-form-select-${field.key}`"
                        :ref="field.key"
                        :value="field.model"
                        :rules="field.rules"
                        :item-text="field.text"
                        :item-value="field.id"
                        :items="field.items"
                        :error-messages="errors[field.key]"
                        :disabled="field.forceDisabled ? true : disabled"
                        solo
                        flat
                        :return-object="true"
                        @input="
                          (evt) => {
                            selectedEvent(
                              evt,
                              field.modelName,
                              field,
                              field.key
                            )
                          }
                        "
                      />
                    </div>
                  </template>

                  <template v-if="field.type === 'multiselect'">
                    <label>
                      {{ field.label }}
                    </label>
                    <div :id="`base-form-select-${field.key}-type-container`">
                      <CRSelect
                        :id="`base-form-multi-select-${field.key}`"
                        :ref="field.key"
                        :value="field.model"
                        :rules="field.rules"
                        :item-text="field.text"
                        :item-value="field.id"
                        :items="field.items"
                        :error-messages="errors[field.key]"
                        :disabled="field.forceDisabled ? true : disabled"
                        chips
                        multiple
                        solo
                        flat
                        :return-object="true"
                        @input="
                          (evt) => {
                            selectedEvent(
                              evt,
                              field.modelName,
                              field,
                              field.key
                            )
                          }
                        "
                      />
                    </div>
                  </template>

                  <div
                    v-if="field.type === 'check-field'"
                    :id="`base-form-select-${field.key}-type-container`"
                    class="amenities"
                  >
                    <v-checkbox
                      v-for="check in row[field.key] || field.items"
                      :id="`base-form-checkbox-${check.key}`"
                      :key="check[field.checkKey]"
                      :ref="field.key"
                      v-model="check[field.checkModel]"
                      class="checkbox"
                      :disabled="field.forceDisabled ? true : disabled"
                      :label="check[field.checkLabel]"
                      :ripple="false"
                      :rules="field.rules"
                      @change="
                        (evt) => {
                          onCheck(evt, field.key, field)
                        }
                      "
                    />
                  </div>
                  <div
                    v-if="field.type === 'checkbox'"
                    :id="`base-form-select-${field.key}-type-container`"
                    class="amenities"
                  >
                    <v-checkbox
                      :id="`base-form-checkbox-${field.key}`"
                      :key="`base-form-checkbox-${field.key}`"
                      :ref="field.key"
                      v-model="row[field.model]"
                      class="checkbox"
                      :disabled="field.forceDisabled ? true : disabled"
                      :label="field.label"
                      :ripple="false"
                      :rules="field.rules"
                      @change="
                        (evt) => {
                          if (!evt) {
                            evt = false
                          }
                          onCheck(evt, field.key, field)
                        }
                      "
                    />
                  </div>

                  <label v-if="field.type === 'text-box'">
                    {{ field.label }}
                  </label>
                  <v-textarea
                    v-if="field.type === 'text-box'"
                    :id="`base-form-text-box-${field.key}`"
                    :ref="field.key"
                    :value="op(row, field.key)"
                    :rules="field.rules"
                    :class="`is-disabled-${disabled}`"
                    :placeholder="field.placeholder"
                    :disabled="
                      field.readOnly
                        ? false
                        : field.forceDisabled
                        ? true
                        : disabled
                    "
                    :readonly="field.readOnly ? disabled : false"
                    solo
                    flat
                    :rows="field.rows || 5"
                    :error-messages="errors[field.key]"
                    @change="
                      (evt) => {
                        onChange(evt, field.key, field)
                      }
                    "
                  />
                  <v-flex
                    v-if="field.type === 'row-block'"
                    class="row-block-container"
                  >
                    <div
                      v-for="child in field.children"
                      :key="child.key"
                      :class="`row-block ${child.key}`"
                    >
                      <CRInput
                        v-if="child.type === 'text'"
                        :id="`base-form-child-${child.key}-text-${field.key}`"
                        :ref="child.key"
                        :tooltip="child.tooltip"
                        :color="child.color"
                        :rules="child.rules"
                        :value="op(row, child.key)"
                        :disabled="field.forceDisabled ? true : disabled"
                        :floatinglabel="child.label"
                        :error-messages="errors[child.key]"
                        @change="
                          (evt) => {
                            onChange(evt, child.key, field)
                          }
                        "
                      />

                      <CRInput
                        v-if="child.type === 'phone'"
                        :id="`base-form-child-${child.key}-phone-${field.key}`"
                        :ref="child.key"
                        v-mask="['(###) ###-####', '+## ## #### ####']"
                        :tooltip="child.tooltip"
                        :color="child.color"
                        :rules="child.rules"
                        :value="op(row, child.key)"
                        :disabled="field.forceDisabled ? true : disabled"
                        :floatinglabel="child.label"
                        :error-messages="errors[child.key]"
                        @change="
                          (evt) => {
                            onChange(evt, child.key, field)
                          }
                        "
                      />

                      <label v-if="child.type === 'select'">
                        {{ child.label }}
                      </label>
                      <CRSelect
                        v-if="child.type === 'select'"
                        :id="`base-form-child-${child.key}-select-${field.key}`"
                        :ref="child.key"
                        :value="child.model"
                        :rules="child.rules"
                        :item-text="child.text"
                        :item-value="child.id"
                        :items="child.items"
                        :disabled="child.forceDisabled ? true : disabled"
                        solo
                        flat
                        :error-messages="errors[child.key]"
                        :return-object="true"
                        @input="
                          (evt) => {
                            selectedEvent(
                              evt,
                              child.modelName,
                              child,
                              child.key
                            )
                          }
                        "
                      />
                      <div
                        v-if="child.type === 'check-field'"
                        class="amenities"
                      >
                        <v-checkbox
                          v-for="check in row[child.key] || child.items"
                          :id="`base-form-child-${child.key}-text-${field.key}`"
                          :key="check[child.checkKey]"
                          v-model="check[child.checkModel]"
                          :label="check[child.checkLabel]"
                          :disabled="child.forceDisabled ? true : disabled"
                          @change="
                            (evt) => {
                              onCheck(evt, child.key, child)
                            }
                          "
                        />
                      </div>
                      <v-checkbox
                        v-if="child.type === 'single-check-field'"
                        :id="`base-form-child-${child.key}-text-${field.key}`"
                        :key="child.checkKey"
                        v-model="row[child.checkModel]"
                        :label="child.checkLabel"
                        :disabled="child.forceDisabled ? true : disabled"
                        @change="
                          (evt) => {
                            onCheck(evt, child.key, child)
                          }
                        "
                      />
                    </div>
                  </v-flex>
                  <v-divider v-if="field.type === 'divider'" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>No Data</div>
        <v-layout justify-center>
          <slot name="bottom" />
        </v-layout>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import op from 'simple-object-path'
import { capitalize } from '@/utils/string'
import { mask } from 'vue-the-mask'

export default {
  name: 'BaseForm',
  directives: {
    mask,
  },
  props: {
    id: { type: String, default: () => null },
    toggle: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => null },
    mode: { type: String, default: () => 'view' },
    formType: { type: String, default: () => 'Vehicle' },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: true }, // eslint-disable-line
    forceDisabled: { type: Boolean, default: false },
    layout: { type: Array, default: () => [] },
    changeCb: {
      type: Function,
      default: () => {
        return function () {}
      },
    },
    selectModels: { type: Object, default: () => ({}) },
    hideHeader: { type: Boolean, default: false },
  },
  data() {
    return { errors: {} }
  },
  methods: {
    overClass(field) {
      let cssClass =
        (field.type === 'check-field' &&
          (this.row[field.key] || field.items).length > 1) ||
        field.type === 'text-box'
          ? `input-space ${field.label.toLowerCase().split(' ').join('-')}`
          : `input-space box-height ${field.label
              .toLowerCase()
              .split(' ')
              .join('-')}`
      if (field.meta && field.toggle === false) {
        cssClass += ' toggle-off '
      }

      return cssClass
    },
    onCheck(evt, fieldKey, field) {
      this.changeCb(evt, fieldKey, field)
    },
    clearCustomError(fieldKey) {
      this.errors[fieldKey] = null
      this.$forceUpdate()
    },
    validateAll() {
      /*
        Originally, I had kept this to be not a form so the first button clicked wouldn't cause the page to reload which caused Josh some issues.
        So, just in case, if we revert this then we'll need the logic. So for now it's fine as isssss. Besides, to prevent submission from reloading we can just prevent default/prop etc.
      */
      const validationsOut = []
      const validateNative = this.$refs.form.validate()
      if (!validateNative) {
        validationsOut.push(
          ...Object.keys(this.$refs.form.errorBag).map((key) => {
            return this.$refs.form.errorBag[key]
          })
        )
      }
      validationsOut.push(
        ...Object.keys(this.$refs)
          .map((el) => {
            if (this.$refs[el][0]) {
              this.$refs[el][0].name = el
              return this.$refs[el][0]
            }

            return null
          })
          .filter(Boolean)
          .reduce((acc, el) => {
            if (!el.rules) {
              return acc
            }

            const elementState = el.rules.map((rule) => {
              let testSubject = el.value

              testSubject = testSubject || el.$attrs.value

              if (el.selectedItems && el.selectedItems[0]) {
                testSubject = el.selectedItems
              }

              const test = rule(testSubject)
              if (typeof test === 'string') {
                this.errors[el.name] = [test]
                return test
              }

              return false
            })

            acc = [...acc, ...elementState]
            return acc
          }, [])
          .filter(Boolean)
      )

      return validationsOut
    },
    onChange(evt, fieldKey, field) {
      if (fieldKey.indexOf('/') > -1) {
        const path = fieldKey.split('/')
        const ref = path.reduce((ref, subpath, index) => {
          if (index === path.length - 1) {
            return ref
          }

          return ref[subpath]
        }, this.row)
        ref[path[path.length - 1]] = evt && evt.target ? evt.target.value : evt
      } else {
        this.row[fieldKey] = evt && evt.target ? evt.target.value : evt
      }

      this.clearCustomError(fieldKey)
      this.changeCb(evt, fieldKey, field)
    },
    selectedEvent(evt, modelName, field, fieldKey) {
      this.selectModels[modelName] = evt
      this.clearCustomError(fieldKey)
      this.changeCb(evt, modelName, field)
    },
    capitalize,
    op,
  },
}
</script>

<style lang="scss" scoped>
.top-items {
  position: absolute;
  top: 4px;
  right: 22px;
}
.width-30 {
  flex: 1 1 50%;
  max-width: 400px;
  margin-right: 3%;
}

.input-space {
  margin: 2% 0;
}

.box-height {
  max-height: 80px;
}

.empty {
  height: 67px;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.toggle-off {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .form-container {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-direction: column-reverse;
    width: 100%;
  }
}

.amenities {
  display: grid;
  grid-template-rows: repeat(3, 35px);
  grid-template-columns: repeat(3, minmax(140px, 150px));
}

.card-title {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 6px;
}

.row-block-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-block {
  // max-width: 48%;
  max-width: 30%;
}

.checkbox {
  display: flex;
}

.base-form-title {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.03em;
}

.is-disabled-true ::v-deep textarea {
  cursor: not-allowed;
}
</style>

<style scoped>
::v-deep .input-space.description {
  margin-top: 20px;
}

::v-deep input[disabled],
::v-deep input:disabled,
::v-deep textarea[disabled] {
  cursor: not-allowed;
  background-color: $blue-dull !important;
}
</style>

<style>
.amenities > .checkbox > .v-input__control > .v-input__slot {
  background-color: transparent !important;
}
</style>
