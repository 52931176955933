<template>
  <BaseForm
    id="market-rates-form"
    ref="form"
    :disabled="disabled"
    :form-type="formType"
    :layout="layout"
    :loading="loading"
    :row="row"
    :select-models="selectModels"
  >
    <template #top>
      <v-snackbar
        v-model="displayerr"
        color="error"
        :multi-line="isModeMultiLine"
        :timeout="0"
        :vertical="isModeVertical"
        class="errormsg"
        type="error"
        absolute
      >
        {{ errmsg }}
        <v-btn
          id="market-rates-form-button-close"
          dark
          flat
          @click="displayerr = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      <v-btn
        v-if="!isModeView"
        id="market-rates-form-button-cancel"
        class="btn-secondaryaction"
        @click="cancelAction"
      >
        Cancel
      </v-btn>
      <CRSelect
        v-else
        class="d-inline-flex"
        item-text="marketName"
        :items="allMarkets"
        :placeholder="row.marketName"
        @input="changeMarket"
      />
      <v-btn
        v-if="isModeAdd"
        id="market-rates-form-add"
        :disabled="loading"
        :loading="loading"
        color="primary"
        @click="submit"
      >
        Add Market Rate
      </v-btn>
      <v-btn
        v-else-if="disabled"
        id="market-rates-form-button-edit"
        color="primary"
        @click="enableEdit"
      >
        Edit Market Rate
      </v-btn>
      <v-btn
        v-else
        id="market-rates-form-button-save"
        :disabled="loading"
        :loading="loading"
        color="primary"
        @click="submit"
      >
        Save Market Rate
      </v-btn>
    </template>
    <template #address>
      <div>Address</div>
      <v-combobox
        id="market-rates-form-combobox-address"
        ref="addr"
        v-model="selectModels.formattedAddress"
        :disabled="disabled"
        :items="asyncitems"
        :search-input.sync="search"
        box
        flat
        solo
        :error-messages="addresserr"
        :rules="[
          isRequired(true, isNotEmpty, {
            req: 'Address Required',
            error: 'Address Required',
          }),
        ]"
        @input="clearOldAddrErr"
      />
    </template>
    <template #market-rates>
      <table class="table is-striped">
        <colgroup>
          <col span="1" style="width: 40%" />
          <col span="1" style="width: 20%" />
          <col span="1" style="width: 20%" />
          <col span="1" style="width: 20%" />
        </colgroup>
        <thead>
          <th style="text-align: left">Rate Type</th>
          <th>Floor Rate</th>
          <th>Low Rate</th>
          <th>High Rate</th>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in row.marketRateDTOs"
            :key="item.marketRateId"
          >
            <td class="rates-td-padded">
              <b>{{ item.vehicleTypeDTO.label }}</b>
              <p>{{ item.marketRateTypeDTO.label }}</p>
            </td>
            <td>
              <CRInput
                :id="`market-rates-form-input-floor-rate-${index}`"
                :ref="index + 'floorRate'"
                v-model="item.floorRate"
                :disabled="disabled"
                type="number"
                style="margin-top: 12px"
              />
            </td>
            <td>
              <CRInput
                :id="`market-rates-form-input-low-rate-${index}`"
                :ref="index + 'lowRate'"
                v-model="item.lowRate"
                :disabled="disabled"
                type="number"
                style="margin-top: 12px"
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'Rate Required',
                    error: 'Rate Required',
                  }),
                ]"
              />
            </td>
            <td>
              <CRInput
                :id="`market-rates-form-input-high-rate-${index}`"
                :ref="index + 'highRate'"
                v-model="item.highRate"
                :disabled="disabled"
                style="margin-top: 12px"
                type="number"
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'Rate Required',
                    error: 'Rate Required',
                  }),
                ]"
              />
            </td>
          </tr>
          <tr>
            <td class="rates-td-padded">
              <b>Driver Rate</b>
              <p>Daily Rate</p>
            </td>
            <td>
              <CRInput
                :id="`market-rates-form-input-additional-driver-rate`"
                ref="driverRate"
                v-model="driverRate.rate"
                :disabled="disabled"
                type="number"
                style="margin-top: 12px"
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'Rate Required',
                    error: 'Rate Required',
                  }),
                ]"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue'
import driverRates from '@/services/driverRates'
import markets from '@/services/markets'
import places from '@/services/places'
import { authComputed } from '@/state/helpers'
import { isNotEmpty, isRequired } from '@/utils/validators'

export default {
  name: 'MarketRateForm',
  components: {
    BaseForm,
  },
  metaInfo() {
    const meta = {
      title: '',
    }

    if (this.isModeEdit) {
      meta.title = `Edit: ${this.row.marketName}`
    } else if (this.isModeAdd) {
      meta.title = 'Add Market Rate'
    } else if (this.isModeView) {
      meta.title = `${this.row.marketName}`
    }

    return meta
  },
  props: {
    id: {
      type: String,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => 'view',
    },
  },
  data() {
    return {
      displayerr: false,
      addresserr: [],
      errmsg: '',
      asyncitems: [],
      search: null,
      addressBank: [],
      loading: false,
      disabled: false,
      selectModels: {},
      row: {},
      layout: [],
      driverRate: {
        rate: null,
        marketId: null,
        companyId: null,
        marketplace: false,
      },
      formType: 'Market',
      allMarkets: [],
    }
  },
  computed: {
    ...authComputed,
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeVertical() {
      return this.mode === 'vertical'
    },
    isModeMultiLine() {
      return this.mode === 'multi-line'
    },
  },
  watch: {
    async search(val) {
      this.selectModels.formattedAddress = val

      if (this.loading) {
        return
      }

      this.loading = true
      const data = await places.addressAutoComplete(
        this.selectModels.formattedAddress
      )

      const items = data?.data

      this.asyncitems = items.map((place) => place.description)

      this.addressBank = this.addressBank.concat(items)

      this.loading = false
    },
    $route: {
      deep: true,
      handler() {
        this.loadView()
      },
    },
  },
  updated() {
    if (this.row) {
      if (this.isModeEdit) {
        this.formType = `Edit: ${this.row.marketName}`
      } else if (this.isModeAdd) {
        this.formType = 'Add Market Rate'
      } else if (this.isModeView) {
        this.formType = this.row.marketName
      }
    }
  },
  async mounted() {
    this.loadView()
  },
  methods: {
    async loadView() {
      this.disabled = this.isModeView

      const allMarketData = await markets.tableView({
        pageSize: -1,
      })
      this.allMarkets = allMarketData?.data?.resultList || []
      if (this.isModeAdd) {
        const vehicleTypeData = await this.$store.dispatch(
          'types/getVehicleTypes'
        )
        const vehicleTypes = vehicleTypeData.data.resultList

        const marketRateData = await this.$store.dispatch(
          'types/getMarketRateTypes'
        )
        let marketRateTypes = marketRateData.data
        marketRateTypes = marketRateTypes.sort((a, b) => {
          const order = { 'daily_rate': 0, 'hourly_rate': 1, 'mileage': 2 };
          return order[a.key] - order[b.key];
        });

        this.row.marketRateDTOs = []
        let accumulator = 0
        vehicleTypes.forEach((vehicle) => {
          marketRateTypes.forEach((market) => {
            if (market.key === 'transfer_rate' || market.key === 'deadmile')
              return

            this.row.marketRateDTOs.push({
              marketRateId: accumulator + 1,
              highRate: '',
              lowRate: '',
              floorRate: '',
              marketRateTypeDTO: {
                id: market.id,
                key: market.key,
                label: market.label,
              },
              marketRateTypeKey: market.key,
              vehicleTypeDTO: {
                id: vehicle.id,
                key: vehicle.key,
                label: vehicle.label,
              },
              vehicleTypeKey: vehicle.key,
            })
            accumulator += 1
          })
        })
      } else {
        const marketRateData = await markets.byId(this.id).catch((error) => {
          throw new Error(error)
        })
        const vehicleTypeData = await this.$store.dispatch(
          'types/getVehicleTypes'
        )
        const vehicleTypes = vehicleTypeData.data.resultList
        const marketRateTypesData = await this.$store.dispatch(
          'types/getMarketRateTypes'
        )
        const marketRateTypes = marketRateTypesData.data

        try {
          const driverRateResponse = await driverRates.getDriverRate({
            marketId: this.id,
            isMarketplace: false,
          })
          if (driverRateResponse) {
            this.driverRate = driverRateResponse.data.driverRate
          }
        } catch {
          this.driverRate.market = this.id
        }

        if (marketRateData) {
          marketRateData.data.market.marketRateDTOs = marketRateData.data.market.marketRateDTOs.filter(
            (rate) => rate.companyId === this.currentUser.companyId
          )
          marketRateData.data.market.marketRateDTOs.forEach((marketRateDTO) => {
            marketRateData.data.market.marketFloors.forEach((marketFloor) => {
              if (
                marketFloor.marketRateTypeKey ===
                  marketRateDTO.marketRateTypeKey &&
                marketFloor.vehicleTypeKey === marketRateDTO.vehicleTypeKey
              ) {
                marketRateDTO.floorRate = marketFloor.floorRate
              }
            })
          })
          const marketRateDataDTOs = marketRateData?.data?.market?.marketRateDTOs || []

          vehicleTypes.forEach((vehicle) => {
            marketRateTypes.forEach((market) => {
              if (market.key === 'transfer_rate' || market.key === 'deadmile') {
                return
              }
              let typeMatch = false
              marketRateDataDTOs.forEach((marketRateDTO) => {
                if (
                  marketRateDTO.marketRateTypeDTO?.label === market.label &&
                  marketRateDTO.vehicleTypeDTO?.label === vehicle.label
                ) {
                  typeMatch = true
                }
              })
              if (!typeMatch) {
                marketRateData.data.market.marketRateDTOs.push({
                  active: true,
                  companyId: this.currentUser.companyId,
                  marketId: parseInt(this.id),
                  highRate: 0,
                  lowRate: 0,
                  floorRate: 0,
                  marketRateTypeDTO: {
                    id: market.id,
                    key: market.key,
                    label: market.label,
                  },
                  marketRateTypeKey: market.key,
                  vehicleTypeDTO: {
                    id: vehicle.id,
                    key: vehicle.key,
                    label: vehicle.label,
                  },
                  vehicleTypeKey: vehicle.key,
                })
              }
            })
          })

          marketRateDataDTOs.sort((a, b) => {
            if (a?.vehicleTypeDTO?.id < b?.vehicleTypeDTO?.id) {
              return -1
            } else if (a?.vehicleTypeDTO?.id > b?.vehicleTypeDTO?.id) {
              return 1
            } else {
              const order = { 'daily_rate': 0, 'hourly_rate': 1, 'mileage': 2 };
              return order[a.marketRateTypeDTO.key] - order[b.marketRateTypeDTO.key];
            }
          })

          this.row = marketRateData.data.market
        }

        this.selectModels.formattedAddress = Object.keys(this.row.addressDTO)
          .map((addressKey) => {
            const filters = ['city', 'state', 'postalCode']

            if (
              filters.includes(addressKey) &&
              this.row.addressDTO[addressKey].length
            ) {
              return this.row.addressDTO[addressKey]
            }

            return null
          })
          .filter(Boolean)
          .join(', ')
      }

      this.layout = [
        {
          id: 1,
          fields: [
            {
              key: 'marketName',
              label: 'Name',
              type: 'text',
              rules: [
                isRequired(true, isNotEmpty, {
                  req: 'Display Name Required',
                  error: 'Display Name Required',
                }),
              ],
            },
            {
              label: 'address',
              type: 'slot',
            },
            {
              key: 'notes',
              label: 'Notes',
              type: 'text-box',
            },
            {
              label: 'market-rates',
              type: 'slot',
            },
          ],
        },
      ]
    },
    changeMarket(market) {
      this.allMarkets.forEach((marketObj) => {
        if (marketObj.marketName === market) {
          this.$router.push(`/market-rates/view/${marketObj.marketId}`)
        }
      })
    },
    displayErr(e, status) {
      this.displayerr = status
      this.errmsg = e
    },
    testAddr() {
      return this.$refs.addr.rules
        .map((fn) => fn(this.$refs.addr.selectedItems))
        .filter((reply) => typeof reply === 'string')
    },
    clearOldAddrErr() {
      this.addresserr = []
      this.$forceUpdate()
    },
    async submit() {
      this.loading = true
      let newAddress = {}
      let oldAddress = {}

      const test = this.$refs.form.validateAll()
      const addrTest = this.testAddr()
      if (test.length > 0 || addrTest.length > 0) {
        if (addrTest.length > 0) {
          this.addresserr = addrTest
        }

        this.loading = false
        this.displayErr(
          'Some fields are missing that may be Required. or are invalid.',
          true
        )
        return
      }

      const addressChange = this.addressBank.filter(
        (addy) => addy.description === this.selectModels.formattedAddress
      )

      if (addressChange.length > 0) {
        newAddress = await places.placeDetailFormatted(
          addressChange[0].place_id
        )
        oldAddress = Object.assign(oldAddress, this.row.addressDTO)
        this.row.addressDTO = {}
      }

      if (!newAddress.lat) {
        newAddress = oldAddress
      }

      this.row.addressDTO = Object.assign(this.row.addressDTO, newAddress)

      if (this.isModeAdd) {
        this.row.companyId = this.currentUser.companyId
        this.row.marketRateDTOs.forEach((rate) => {
          rate.marketplace = true
          rate.active = true
          // this value is used for updating the model when adding a new market rate, and can't be sent to the api
          delete rate.marketRateId
        })
        this.row.marketFloors = this.row.marketRateDTOs
        try {
          const createdMarketRate = await markets.create({
            id: this.id,
            payload: this.row,
          })

          this.driverRate.marketId = createdMarketRate.data
          this.driverRate.companyId = this.currentUser.companyId
          const createdDriverRate = await driverRates.addDriverRate(
            this.driverRate
          )

          if (createdMarketRate && createdDriverRate) {
            this.loading = false
            this.disabled = !this.disabled
            this.$router.push(
              `/market-rates${
                createdMarketRate.data ? `/view/${createdMarketRate.data}` : ''
              }`
            )
            this.$store.dispatch('app/showAlert', {
              message: 'Market rate added.',
              type: 'success',
            })
          }
        } catch (err) {
          this.loading = false
          this.displayWarning = true
          this.warningMessage = err
          return false
        }
      } else {
        this.row.marketRateDTOs.forEach((rate) => {
          rate.marketplace = true
        })
        this.row.marketFloors = this.row.marketRateDTOs
        try {
          const updatedMarketRate = await markets.update({
            id: this.id,
            payload: this.row,
          })

          let updateDriverRate
          // make sure a driver rate already exists, otherwise create it
          if (this.driverRate?.driverRateId) {
            updateDriverRate = await driverRates.editDriverRate(this.driverRate)
          } else {
            this.driverRate.marketId = this.id
            this.driverRate.companyId = this.currentUser.companyId
            updateDriverRate = await driverRates.addDriverRate(this.driverRate)
          }

          if (updatedMarketRate && updateDriverRate) {
            this.loading = false
            this.disabled = !this.disabled
            this.$router.push(
              `/market-rates${this.id ? `/view/${this.id}` : ''}`
            )
            this.$store.dispatch('app/showAlert', {
              message: 'Market rate updated.',
              type: 'success',
            })
          }
        } catch (err) {
          this.loading = false
          this.displayWarning = true
          this.warningMessage = err

          return false
        }
      }
    },
    enableEdit() {
      this.disabled = !this.disabled
      this.$router.push(`/market-rates/edit/${this.id}`)
    },
    cancelAction() {
      if (this.isModeEdit) {
        this.disabled = true
        this.$router.push(`/market-rates/view/${this.id}`)
        return
      }

      this.$router.push('/market-rates')
    },
    isNotEmpty,
    isRequired,
  },
}
</script>

<style lang="scss">
.box-height.market-rates {
  max-height: unset;

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 0;
  }
}

.rates-td-padded {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  p {
    margin: 0;
  }
}
</style>
