import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { DriverRateDTO } from '@/models/dto/Rate'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getDriverRate(params: { companyId: number, marketId: number, isMarketplace: boolean }): Promise<AxiosResponse<DriverRateDTO>> {
    const host = baseUrl()
    let companyIdQuery = ''
    if (params.companyId) {
      companyIdQuery = `&affiliateCompanyId=${params.companyId}`
    }
    const url = `https://${host}/driverRate/companyMarket?marketId=${params.marketId}&marketplace=${params.isMarketplace}${companyIdQuery}`
    return httpService.get(url)
  },
  addDriverRate(payload: DriverRateDTO): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/driverRate`
    return httpService.post(url, payload)
  },
  editDriverRate(payload: DriverRateDTO): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/driverRate`
    return httpService.patch(url, payload)
  },
  deleteDriverRate(driverRateId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/driverRate/${driverRateId}`
    return httpService.delete(url)
  },
}
